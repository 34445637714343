//store/modules/auth.js

import axios from 'axios';

const state = {
    list:null
};

const getters = {
    
};

const actions = {
    async getCampaignInfo({dispatch}, form) {

        const headers =  {
            Authorization: '',
        }

        const response = await axios.get('campaign-detail-by-encrypted',{ params: form, headers: headers})
        return response
    },


    async getNearestOutlets({dispatch}, form) {
        const headers =  {
            Authorization: '',
        }

        const response = await axios.get('campaign-outlet-nearest-by-langlat',{ params: form, headers: headers})
        return response
    },
    
    async getPredictedOutlet({dispatch}, form) {
        const headers =  {
            Authorization: '',
        }

        const response = await axios.get('campaign-outlet-prediction',{ params: form, headers: headers})
        return response
    },

    async useVoucher({dispatch}, form) {
        const headers =  {
            headers: {
                Authorization: '',
            }
        }

        const response = await axios.post('usevoucer',form, headers)
        return response
    },
    
    async getVoucher({dispatch}, form) {
        const headers =  {
            headers: {
                Authorization: ''
            }
        }

        const response = await axios.post('getvoucher/'+form.encrypted_code,form, headers)
        return response
    },
   
    async outletCheck({dispatch}, form) {
        const headers =  {
            headers: {
                Authorization: ''
            }
        }

        const response = await axios.post('outlet-check',form, headers)
        return response
    },
    
    async detailJob({dispatch}, form) {
        const headers =  {
                Authorization: $cookies.get("ZEALS_TOKEN") ? `Bearer ${$cookies.get("ZEALS_TOKEN")}` : '',
            }
        
        const response = await axios.get('job-detail',{ params: form})
        return response
    },
    
    async deleteJob({dispatch}, form) {
        const headers =  {
                Authorization: $cookies.get("ZEALS_TOKEN") ? `Bearer ${$cookies.get("ZEALS_TOKEN")}` : '',
            }
        
        const response = await axios.delete('job-delete',{ params: form, headers: headers})
        return response
    },

};

const mutations = {
    
};

export default {
  state,
  getters,
  actions,
  mutations
};
