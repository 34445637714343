import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/:any',
    name: 'any',
  },
  {
    path: '/',
    name: 'index'
  },
]


const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
