<template>
    <div class="floater" v-if="show">
        <b-spinner class="loading" type="grow" variant="default" ></b-spinner>
    </div>
</template>
<script>
export default {
    props:{
        show:{
            required:true,
            default:false
        }
    }
}
</script>
