

import { createStore } from 'vuex'

import createPersistedState from "vuex-persistedstate";
import voucher from './modules/voucher';


export default createStore({
  modules: {
    voucher
  },
  plugins: [createPersistedState()]
});
