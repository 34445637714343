<template>
  <div class="viewer" ref="viewer"></div>
  <div class="voucher">
    <div class="content-top">
      <div class="top-container">
        <div class="frame-top">
          <font-awesome-icon icon="fa-solid fa-circle" />
          <font-awesome-icon icon="fa-solid fa-circle" />
          <font-awesome-icon icon="fa-solid fa-circle" />
          <font-awesome-icon icon="fa-solid fa-circle" />
          <font-awesome-icon icon="fa-solid fa-circle" />
        </div>
        <div class="header" style="text-align: center;">
          <div class="relative" style="width: fit-content; display: inline-block;position: relative;font-weight: 900 !important;">
            <img src="../src/assets/logo_transparent.png" style="width: 40px;position: absolute;left: -38px;top: 7px;transform: rotate(-20deg);">
            COUPON
          </div>
        </div>
      </div>
    </div>
    <div class="middle" v-if="step==0">
      <div class="content">
        <div class="valid-until" v-if="valid_until">*valid until {{ valid_until }}</div>

        <img :src="base_url+'/'+campaign.photos" class="banner" v-if="campaign.photos">

        <div class="description" >
          <h1>
            {{ campaign.campaign_title }}
          </h1>
          <div v-html="campaign.campaign_description"></div>
        </div>

        <div class="multiproducts">
          <h3>Valid Products: ({{ (campaign.products)?(campaign.products.length>1>1? campaign.products.length+' items':campaign.products.length+' item'):'0 item' }})</h3>
          <div class="product-item" v-for="(item, index) in campaign.products" v-bind:key="index">
            <font-awesome-icon icon="fa-solid fa-box" /> 
            <span>
              {{ item.product_name }}
            </span>
          </div>
        </div>
      </div>
    </div>

    <div class="middle" v-if="step==1">
      <div class="content">
        <div class="info" v-if="!need_coordinate">
          <img src="../src/assets/img/voucher_animation.gif">
          <h1>Yeaaay!!!</h1>
          <p class="big-p">Congratulation! Your coupon is now ready for redemption. Please go to the outlet then scan the QR to redeem this coupon!
          </p>
        </div>

        <div class="info" v-else>
          <img src="../src/assets/img/gps_animation.gif" style="width:80%;margin-bottom: 50px;">
          <h1 class="text-danger">Oops!!!</h1>
          <p class="big-p">Please activate your GPS Location to help us find the nearest outlet for you.
          </p>
          <p class="mt-2 mb-2">
            <b-button class="btn-enable" @click="getLocation()">Enable GPS location</b-button>
          </p>
        </div>
        
      </div>
    </div>

    <div class="middle" v-if="step==2">
      <div class="content">
        <StreamBarcodeReader @decode="onDecode" @load="onLoaded" @error="onError" class="mt-4 scanner"></StreamBarcodeReader>
      </div>
    </div>

    <div class="middle" v-if="step==3">
      <div class="content">
        <div class="row mt-3">
          <div class="col-12 text-left">
            <label>Full Name</label>
            <b-form-input v-model="optin.full_name" placeholder="example: Jessica"></b-form-input>
          </div>

          <div class="col-12 text-left mt-2">
            <label>Phone Number</label>
            <div class="input-group">
              <span class="input-group-addon">+62</span>
              <b-form-input v-model="optin.phone" type="tel" placeholder="822144xxxx"></b-form-input>
            </div>
          </div>

          <!-- <div class="col-12 text-left mt-2">
            <label>Items in your transaction ({{ countRedeemItem()>1? countRedeemItem()+' items':countRedeemItem()+' item' }})</label>
            <div class="multiproducts" style="margin-top: 5px;">
              <div class="product-item" v-for="(item, index) in campaign.products" v-bind:key="index" @click="redeemItem(item)">
                <font-awesome-icon icon="fa-solid fa-circle" v-if="!item.redeem"/> 
                <font-awesome-icon icon="fa-solid fa-check-circle" v-if="item.redeem"/> 
              
                {{ item.product_name }}
              </div>
            </div>
          </div> -->

          <!-- <div class="row text-left mt-3">
            <label>Redeem Location</label>
            <div class="outlets" style="margin-top:0px;padding-top: 0px;">
              <div class="outlet-item" v-if="outlet">
                <font-awesome-icon icon="fa-solid fa-map-marker" /> <span> {{ outlet.outlet_name }} ({{ outlet.outlet_address }})</span>
              </div>
            </div>
          </div> -->

        </div>
      </div>
    </div>

    <div class="outlets" v-if="step!=3">
      <h3>Valid Outlets: 
        <!-- ({{ (campaign.outlets)?campaign.outlets.length:0 }} outlets) -->
      </h3>
      <input class="form-control extra-rounded" placeholder="Find the nearest outlet" v-model="location_search" style="margin-top: 5px;">
      <template v-if="campaign.outlets">
        <div class="outlet-item mt-2" v-for="(item, index) in campaign.outlets" v-bind:key="index">
          <font-awesome-icon icon="fa-solid fa-map-marker" /> <span><strong>{{ item.outlet_name}}</strong> ({{ item.outlet_address }})</span>
        </div>
      </template>
      <template v-else>
        <p class="mt-2">
          No data found for keyword "{{ location_search }}"
        </p>
      </template>
    </div>

    <div class="policy">
      <h3>Terms & Services</h3>
      <div class="terms-data">
        <p>Redemption only available at the outlet listed above. Please make sure the outlet is open for redemption process.</p>
        <p>Zeals are not responsible for the voucher redemption process at the listed outlet. The transaction at the outlet is the brands/outlet responsibility.</p>
      </div>
    </div>
    <div class="footer">
      <img src="https://app.zeals.asia/templates/admin/img/logo.jpg">
    </div>

    <div class="control-action">
      <div class="action-container">

        <button type="button" v-if="step==0" class="btn-action" @click="goNextStep()" :disabled="(campaign.campaign_title && !loading)?false:true">Get Voucher</button>
        <button type="button" v-if="step==1" class="btn-action" @click="goNextStep()" :disabled="need_coordinate && !loading">Scan QR Now</button>
        <button type="button" v-if="step==2" class="btn-action" @click="cancelStep()">Cancel</button>
        <button type="button" v-if="step==3" class="btn-redeem" :disabled="(checkMandatory())?false:true" @click="doUseVoucher()">Redeem Now</button>
        
        <div class="frame-bottom">
          <font-awesome-icon icon="fa-solid fa-circle" />
          <font-awesome-icon icon="fa-solid fa-circle" />
          <font-awesome-icon icon="fa-solid fa-circle" />
          <font-awesome-icon icon="fa-solid fa-circle" />
          <font-awesome-icon icon="fa-solid fa-circle" />
        </div>
      </div>
    </div>
  </div>

  <Alert ref="alerts"></Alert>
  <Loading :show="loading"></Loading>

</template>

<script>
import { mapActions } from "vuex";
import { StreamBarcodeReader } from "vue-barcode-reader";

import Alert from "@/components/AlertTransaction.vue"
import Loading from '@/components/LoadingProgress.vue'

export default {
  name: 'App',
  components: {
    StreamBarcodeReader,
    Alert,
    Loading
  },

  data(){
    return {
      first_timer_check:true,
      step:0,
      voucher_code:"AKLSJGLW131",
      voucher:null,
      encrypted_code:null,
      valid_until:null,
      scan_result:null,
      coordinate:{
        longitude:null,
        latitude:null
      },
      optin:{
        full_name:null
      },
      need_coordinate:true,
      encypted_code:null,
      campaign:{
        banner:null,
        products:[],
        outlets:null
      },
      form:{},
      base_url:'https://app.zeals.asia',
      location_search:null,
      keywordTimeout:null,
      outlet:null, //redeemtion outlet
      loading:false
    }
  },

  watch:{
    '$route.params.any':function(){
      // setTimeout(() => {
       this.encrypted_code = this.$route.params.any
       this.getDetail()
      // },500)
    },

    location_search:function(){
      clearTimeout(this.keywordTimeout)

      this.keywordTimeout = setTimeout(()=>{
        //this.dogetNearestOutlets(this.coordinate, this.location_search)
      },800)
    }
  },

  methods:{
    ...mapActions(["getCampaignInfo","getNearestOutlets","getPredictedOutlet","getVoucher","useVoucher","outletCheck"]),

    cancelStep(){
      this.step = this.step - 1
    },

    checkMandatory(){
      if(this.optin.full_name && this.optin.phone){
        return true
      }else{
        return false
      }
    },

    countRedeemItem(){
      let counter = 0 

      for(let index in this.campaign.products){
        if(this.campaign.products[index].redeem){
          counter++
        }
      }

      return counter
    },

    redeemItem(item){
      item.redeem = !item.redeem
    },

    goNextStep(){

      if(this.step == 0){
        this.doGetVoucher()
      }

      if(this.step == 1){
        this.doGetTheOutlet()
      }

      this.step = this.step + 1

      setTimeout(() => {
        this.$refs.viewer.scrollIntoView({ behavior: 'smooth' });
      },300)

    },

    onDecode (datastring) {
        this.scan_result = datastring
        this.doValidateOutlet()
    },

    getLocation(){
      // this.coordinate = {
      //   longitude: 109.6590283,
      //   latitude: -7.6784122
      // }

      // setTimeout(() => {
      //   this.doGetTheOutlet()
      // },1500)

      navigator.geolocation.getCurrentPosition(
        position => {
          this.coordinate.longitude = position.coords.longitude
          this.coordinate.latitude  = position.coords.latitude
          
          this.need_coordinate  = false
          this.first_timer_check = false
        },
        error => {
          if(!this.first_timer_check){
            window.location.reload()
          }

          this.first_timer_check = false
        },
      )
    },

    getSelectedProducts(){
      let selected_products = []
      for(let index in this.campaign.products){
        if(this.campaign.products[index].redeem){
          selected_products.push(this.campaign.products[index])
        }
      }
      
      return selected_products
    },

    async doGetVoucher(){
      this.voucher = this.$cookies.get("ZEALS_VOUCHER")

      if(!this.voucher){
        this.loading = true
        this.response = null

        const form = {
          encrypted_code : this.encrypted_code
        }

        try {
            console.log(this.form,"DATA")
            const response  = await this.getVoucher(form)
            this.voucher   = response.data
            this.$cookies.set("ZEALS_VOUCHER",this.voucher)

        } catch (error) {
            this.error = error
            this.$refs.alerts.setAlert('error',error)
            this.$refs.alerts.showAlert()
            console.log(error, "ERRPR")
        }

        this.loading = false
      }else{
        console.log(this.voucher, "VCVC")
      }
    },

    async doValidateOutlet(){
      this.loading = true
      this.response = null

      const form = {
        outlet_code : this.scan_result,
        voucher_code : this.voucher.voucher_code,
      }

      try {
          console.log(this.form,"DATA")
          const response  = await this.outletCheck(form)
          if(response.data.status=="invalid"){
            this.$refs.alerts.setAlert("error", "QR is not recognized!")
            this.$refs.alerts.showAlert()
          }else{
            this.outlet  = response.data.data
            console.log("OUTLETS",this.outlet)
            this.goNextStep()
          }

          this.loading  = false

      } catch (error) {
          this.error = error
          this.$refs.alerts.setAlert('error',error)
          this.$refs.alerts.showAlert()
          console.log(error, "ERRPR")
          this.loading = false
      }
    },

    async doUseVoucher(){
      if(this.outlet){
        this.loading = true
        this.response = null

        const form = {
          outlet_code : this.outlet.outlet_code,
          voucher_code : this.voucher.voucher_code,
          longitude : this.coordinate.longitude,
          latitude  : this.coordinate.latitude,
          id_campaign : this.campaign.id_campaign,
          optin_name : this.optin.full_name,
          optin_email : this.optin.phone,
          optin_phone : this.optin.phone,
          encrypted_code : this.encrypted_code,
          products : this.getSelectedProducts()
        }

        try {
            console.log(this.form,"DATA")
            const response  = await this.useVoucher(form)
            if(response.data.status=="valid"){  
              this.$refs.alerts.setAlert('custom-success',response.data.response)
            }else{
              this.$refs.alerts.setAlert("error", response.data.response)
            }

            this.$refs.alerts.showAlert()
            this.loading  = false
        } catch (error) {
            this.error = error
            this.$refs.alerts.setAlert('error',error)
            this.$refs.alerts.showAlert()
            console.log(error, "ERRPR")
        }

        this.loading = false
      }else{
        this.$refs.alerts.setAlert('error',"Your location is out of redemption range!")
        this.$refs.alerts.showAlert()
      }
    },

    async dogetNearestOutlets(coordinate, keyword){
      this.loading = true
      this.response = null

      const form = {
        longitude : coordinate.longitude,
        latitude  : coordinate.latitude,
        keyword   : keyword,
        id_campaign : this.campaign.id_campaign
      }

      try {
          console.log(this.form,"DATA")
          const response  = await this.getNearestOutlets(form)
          if(response.data.status=="success"){  
            this.campaign.outlets = response.data.data
          }else{
            this.campaign.outlets = null
          }
      } catch (error) {
          this.error = error
          this.$refs.alerts.setAlert('error',error)
          this.$refs.alerts.showAlert()
          console.log(error, "ERRPR")
      }

      this.loading = false
    },

    async doGetTheOutlet(){
      this.loading = true
      this.response = null

      const form = {
        longitude : this.coordinate.longitude,
        latitude  : this.coordinate.latitude,
        id_campaign : this.campaign.id_campaign
      }

      try {
          console.log(this.form,"DATA")
          const response  = await this.getPredictedOutlet(form)
          if(response.data.status=="success"){
            // this.outlet   = response.data.data
          }else{
            // this.outlet   = null
          }
      } catch (error) {
          this.error = error
          this.$refs.alerts.setAlert('error',error)
          this.$refs.alerts.showAlert()
          console.log(error, "ERRPR")
      }

      this.loading = false
    },

    async getDetail(){
      this.loading = true
      this.response = null

      const form = {
        encrypted_code : this.encrypted_code
      }

      try {
          console.log(this.form,"DATA")
          const response  = await this.getCampaignInfo(form)
          this.campaign   = response.data.data.campaign
          this.valid_until = response.data.valid_until
      } catch (error) {
          this.error = error
          this.$refs.alerts.setAlert('error',error)
          this.$refs.alerts.showAlert()
          console.log(error, "ERRPR")
      }

      this.loading = false
    }
  },

  mounted(){
    this.getLocation();
    this.encrypted_code = this.$route.params.any
  }
}
</script>
