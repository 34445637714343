<template>
    <BModal :class="type" class="custom-modal" v-model="openModal" hide-header="true" hide-footer="true" no-close-on-backdrop="false" hide-header-close="true">
        <div class="alert alert-danger mb-3 mt-3" v-if="type=='error' || type=='error_validation'">
            <h3><i class="fa-solid fa-triangle-exclamation"></i> Oops!</h3>
            <span v-html="messages"></span>
        </div>

        <div class="alert alert-success text-center  mb-3 mt-3" v-if="type=='success'">
            <h3><i class="fa-solid fa-check"></i> Success</h3>
            <span v-html="messages"></span>
        </div>

        <div class="text-center  mb-3 mt-3" v-if="type=='custom-success'">
            <h3><font-awesome-icon icon="fa-solid fa-check" /></h3>
            <span v-html="messages"></span>
        </div>

        <div class="row">
            <div class="col-12 text-center">
                <b-button class="btn" @click="tutupForm()" v-if="type=='error' || type=='error_validation'">Close</b-button>
                <b-button class="btn" @click="closeForm()" v-if="type=='success' || type=='custom-success'">Close</b-button>
            </div>
        </div>
    </BModal>
</template>
<script>

export default {
    data(){
        return {
            messages:"",
            openModal:false,
            type: ""
        }
    },
    
    methods:{
        setAlert(type, messages){
            console.log(type,"T")
            console.log(messages,"M")

            this.type       = type
            this.messages   = ""
            
            try{
                switch(this.type){
                    case 'error':
                        this.messages = messages
                    break;

                    case 'error_validation':
                        for(let index in messages){
                            for(let index2 in messages[index]){
                                this.messages = this.messages + "<i class='fa-solid fa-times '></i> "+messages[index][index2]+"<br>"
                            }
                        }
                    break;

                    case 'success':
                        this.messages = messages
                    break;

                    default:
                        this.messages = messages
                    break
                }
            }catch(error){
                console.log(error,"err")
            }
        },

        showAlert(){
            console.log('called alert')
            this.openModal = true
        },

        tutupForm(){
            this.openModal = false
        },

        closeForm(){
            window.location.reload()
        }
    }
}
</script>
